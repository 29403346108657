// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@import '@angular/cdk/overlay-prebuilt.css';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core;

@import '@vlaio/styles/variables/colors-eloket';
@import '@vlaio/styles/variables/typography';
@import '@vlaio/styles/base/_globals-common';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$e-loket-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$e-loket-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$e-loket-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$e-loket-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $e-loket-primary,
			accent: $e-loket-accent,
			warn: $e-loket-warn
		)
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($e-loket-theme);

html,
body {
	* {
		user-select: text;
	}
}

vlaio-input-wrapper {
	input {
		border: 1px solid $grey-83 !important;
		box-shadow: none !important;
	}
}

.mdc-snackbar {
	margin-top: 60px !important;
}

html:has(body.ngx-tour-active) {
	// Prevent scrolling when the tour is active
	overflow-y: hidden;
}

html:has(vlaio-video-modal) {
	// Prevent scrolling when a dialog is open
	overflow-y: hidden !important;
}

.ngx-tour-item-active {
	pointer-events: none;
}

// -------------------------------------------------
// :: NgxCookies
// -------------------------------------------------
// Iben: Styling of the cookie handler
// All these importants are set because we need to overwrite the styling
.cm--box {
	background: $white;
	border-radius: 0 !important;

	.cm__desc {
		font-family: $font-family-sans !important;
		font-size: $font-size !important;
	}

	.cm__title {
		font-family: $font-family-sans !important;
		font-size: $font-size-large !important;
	}

	.cm__btn {
		text-transform: uppercase !important;
		background: $primary-color !important;
		border: none !important;
		border-radius: 0 !important;

		&:hover {
			background-color: $button-primary-hover !important;
			border-color: $button-primary-hover !important;
		}

		&.cm__btn--secondary {
			color: $primary-color !important;
			background-color: transparent !important;
			border: 2px solid $primary-color !important;

			&:hover {
				color: $white !important;
			}
		}
	}
}

.pm--box {
	background: $white;
	border-radius: 0 !important;

	.pm__section-title {
		font-family: $font-family-sans !important;
		border-radius: 0 !important;
	}

	.pm__title {
		font-family: $font-family-sans !important;
		font-size: $font-size-large !important;
	}

	.pm__desc,
	.pm__section-desc {
		font-family: $font-family-sans !important;
		font-size: $font-size !important;
	}

	.pm__section-desc {
		font-size: $font-size-small !important;
	}

	.pm__btn {
		text-transform: uppercase !important;
		background: $primary-color !important;
		border: none !important;
		border-radius: 0 !important;

		&.pm__btn--secondary {
			color: $primary-color !important;
			background-color: transparent !important;
			border: 2px solid $primary-color !important;

			&:hover {
				color: $white !important;
			}
		}

		&:hover {
			background-color: $button-primary-hover !important;
			border-color: $button-primary-hover !important;
		}
	}

	.pm__section--toggle,
	.pm__section-desc-wrapper {
		border-radius: 0 !important;
	}

	.pm__service {
		padding: $layout-spacing-lg !important;
	}

	.pm__close-btn {
		background: transparent !important;
		border: none !important;
	}
}

.pm__close-btn,
.pm__section-title,
.section__toggle,
.pm__btn,
.cm__btn {
	&:focus-visible {
		outline: auto !important;
		outline: $link-color solid 2px !important;
		outline-offset: 2px !important;
	}
}
